@import "./_theme";
@import "./_media";
@import "./_mixin";
@font-face {
  font-family: 'AlibabaPuHuiTiRegular';
  src: url('../static/font/AlibabaPuHuiTi-3-55-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'AlibabaPuHuiTiMedium';
  src: url('../static/font/AlibabaPuHuiTi-3-65-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'AlibabaPuHuiTiBold';
  src: url('../static/font/AlibabaPuHuiTi-3-85-Bold.ttf') format('ttf');
}
html,
body {
  width: 100%;
  margin: 0 !important;
  background-color: #fff;
  font-family:Noto Sans S Chinese,  'AlibabaPuHuiTiBold','AlibabaPuHuiTiMedium','AlibabaPuHuiTiRegular',sans-serif;
}

html {
  background-color: #fff;
}

body {
  background-color: #fff;

  li {
    list-style: none;
  }
}
._decline{
  color: #FF7C1C;
}
._riseup {
  color: #316FF5;
}
html,
body,
div,
span,
img,
input,
h1,
h2,
h3,
h4,
h5,
h6,
p,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-size: 100%;
  background: transparent;
}

a {
  text-decoration: none;
}

.noData {
  background-color: #fff;
  text-align: center;
  color: $text-color-3;
  margin-top: rem(200px);
  font-size: $font36;
  opacity: 0.6;

  .noDataText {
    margin-top: rem(30px);
  }
}

// 清除浮动
.clearfix::before,
.clearfix::after {
  display: table;
  content: "";
}

.clearfix::after {
  clear: both;
}

.clearfix {
  *zoom: 1;
  /* 触发IE6、IE7 */
}

// 单行超出省略
.one-line-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// 多行超出省略
.many-line-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}